import Link from 'next/link';

import { AppLinks } from 'utils/links';

function StoryHero() {
  return (
    <div className="StoryHero">
      <div className="StoryHero__content">
        <h3>Story of Fingy</h3>
        <p>
          We live, work and enjoy climbing in the biggest sandstone canyon in
          Europe. Fingy arrived as a training guide for climbers, who strive to
          prepare for the rocks - as much as we&nbsp;do.
        </p>
        <Link href={AppLinks.story} passHref legacyBehavior>
          <a className="Button Button--outline Button--outlineDark">
            Read Our Story
          </a>
        </Link>
      </div>
    </div>
  );
}

export default StoryHero;
