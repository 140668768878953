import Link from 'next/link';

import { AppLinks } from 'utils/links';

function FeaturesHero() {
  return (
    <div className="FeaturesHero">
      <div className="Container">
        <h2>More than you would&nbsp;expect</h2>
        <p className="Lead">
          Our features include all of supposed training essentials, but we
          deliver new ones continuously, usually on demand of our users.
        </p>
        <Link href={AppLinks.features} passHref legacyBehavior>
          <a className="Button Button--outline">Read features</a>
        </Link>
      </div>
    </div>
  );
}

export default FeaturesHero;
